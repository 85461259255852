const initBlock = async function( block ) {
	const serviceCard = block.find( "[data-service-card]" );
	const serviceCardDescription = serviceCard.find( "[data-service-card-description]" );
	serviceCard.removeAttr( "style" );
	serviceCardDescription.removeAttr( "style" );

	serviceCard.each( function() {
		const card = $( this );
		card.css( "min-height", card.find( "[data-service-card-wrapper]" ).outerHeight() );

		$( window ).on( "resize", function() {
			card.removeAttr( "style" );
			card.find( "[data-service-card-description]" ).removeAttr( "style" );
			card.css( "pointer-events", "none" );
			setTimeout( () => {
				card.removeAttr( "style" );
				card.css( "min-height", card.find( "[data-service-card-wrapper]" ).outerHeight() );
			}, 300 );
		} );

		card.on( "mouseenter", function() {
			card.find( "[data-service-card-description]" ).css( "max-height", card.find( "[data-service-card-description]" ).find( ".section-value-prop__service-description-inner" ).outerHeight() );
		} );

		card.on( "mouseleave", function() {
			card.find( "[data-service-card-description]" ).removeAttr( "style" );
		} );
	} );
};

themeUtils.loadBlock( initBlock, "value-prop", ".section-value-prop" );
